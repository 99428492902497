class filter {

    constructor() {

    }

    addEventListener() {

    }

}

const productfilter = new filter();
