class basket {

    public _baskettoggle: JQuery;
    public _basket: JQuery;
    constructor() {
        this._basket = $('.basket-modal');
        this._baskettoggle = $('.open-basket');
        this.addEventListener();
    }

    addEventListener() {
        this._baskettoggle.on('click', event => this.openBasket());
        $('.basket-go-back').on('click', event => this.breadcrumb());
        //$('.basket-go-back').on('click', event => this.pickup());
        $('.pick_up_input').on('click', event => this.pickup());
    }
    openBasket() {
        this._basket.toggleClass('open');
    }

    breadcrumb(){
        window.history.go(-1);
    }

    pickup(){
        if($('.pick_up_input').is(':checked')){

            $('.pick_up_input').val('1');
        } else{
            $('.pick_up_input').val('0');
        }
    }

}

const shoppingbasket = new basket();
