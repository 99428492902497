class profile {

    public _isdropdown: JQuery;
    public _dropdowntoggle: JQuery;
    public _companytoggle: JQuery;
    public _companyfields: JQuery;
    public _editbtn: JQuery;
    constructor() {
        this._isdropdown = $('.is-dropdown');
        this._dropdowntoggle = $('.has-dropdown');
        this._companytoggle = $('.field_toggle');
        this._companyfields = $('.company-fields');
        this._editbtn = $('.edit-btn');
        this.addEventListener();
    }

    addEventListener() {
        this._companytoggle.on('click', event => this.openCompanyFields());
        this._dropdowntoggle.on('click', event => this.openDropDown());
        this._editbtn.on('click', event => this.getEditForm());
    }
    openDropDown() {
        this._isdropdown.toggleClass('open');
    }


    openCompanyFields(){
        if(this._companytoggle.is(':checked')){
            this._companyfields.addClass('open');
        } else{
            this._companyfields.removeClass('open');
        }
    }

    getEditForm(){
        $('.updateuser').toggleClass('is-editable');
    }


}

const userprofile = new profile();
