class navigation {
   // .nav-items
    public _nav: JQuery;

    public _navitems: JQuery;
    public _menutoggle: JQuery;
    public _shoppingnav: JQuery;
    constructor(module){
        this._nav  = module;

        this._navitems = $('.nav-items');
        this._menutoggle = $('.menu-toggle');
        this._shoppingnav = $('.shopping-items');
        this.addEventListener();
    }

    addEventListener(){
        this._menutoggle.on('click', event => this.toggleNavigation());
        $(window).scroll((event) => this.windowScrollHandler());
    }

    toggleNavigation(){
        this._nav.toggleClass('open');
        var has_class;
        if(this._nav.hasClass('open')){
            has_class = true;

            if(has_class == true){
                $('html').addClass('no-scroll');
                has_class = false;
                if(has_class == false){
                    $('html').removeClass('no-scroll');
                }
            }

        }
    }

    windowScrollHandler() {
        const scrollTop = $(window).scrollTop();


        if (scrollTop > 50) {
             if ($(window).width() >= 767 && $(window).width() < 960) {
                this._nav.addClass('is-sticky');
             }
            this._shoppingnav.addClass('is-sticky');

        } else {
            if ($(window).width() >= 767 && $(window).width() < 960) {
                this._nav.removeClass('is-sticky');
             }
            this._shoppingnav.removeClass('is-sticky');
        }
    }
}

const navMenu = new navigation($('.navigation'));
