class cookie {

    constructor() {

        this.addEventListener();
    }

    addEventListener() {
        $('#accept_cookie').on('click', event => this.acceptCookie());
        $('#deny_cookie').on('click', event => this.denyCookie());

        if(document.cookie.indexOf("lavitaitaliana")  >= 0){
            $('.cookie').hide();
        }
    }

    acceptCookie(){
        document.cookie = "lavitaitaliana=cookie_accepted";
        $('.cookie').hide();
    }

    denyCookie(){
        document.cookie = "lavitaitaliana=cookie_denied";
        $('.cookie').hide();
    }

}

const accept = new cookie();
