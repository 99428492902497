class register {


    constructor() {

        this.addEventListener();
    }

    addEventListener() {
        $('.field_toggle').on('click', event => this.setValue());

    }


    setValue(){

        if($('.field_toggle').is(':checked')){
            $('#business_account').val(1);
        } else{
            $('#business_account').val(0);
        }
    }




}

const register_account = new register();
