class imageSlide {

    public _sideImage: JQuery;
    public _mainIMage: JQuery;
    constructor() {
        this._sideImage = $(".side-product-image");
        this._mainIMage = $('.main-product-image');
        this.addEventListener();
    }

    addEventListener() {
        this._sideImage.on('click', event => this.clickHandler(event));
    }

    clickHandler(event) {
        const sideitem = $(event.currentTarget);
        this.sideImageBox(sideitem);
        this.changeMainImage(sideitem);
    }

    sideImageBox(sideitem) {
        if (sideitem.hasClass('active')) return;

        this._sideImage.removeClass('active');
        sideitem.addClass('active');
    }

    changeMainImage(sideitem) {
        this._mainIMage.children('img').attr('src', sideitem.children('img').attr('src'));
    }
}

const slide = new imageSlide();
